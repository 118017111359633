import { Typography as MuiTypography, TypographyProps as MuiTypographyProps } from "@material-ui/core"
import React from "react"
import { capitalize } from "../../services/Utils"
import styles from './Typography.module.scss'

const customVariants = ["menu"]

const isCustomVariant = (variant: string = "") => {
    return customVariants?.includes(variant)
}

export type ExtendedTypographyVariant =
    MuiTypographyProps['variant'] |
    'menu' |
    'body0' |
    'h1colisseum' |
    'ColisseumRegular20' |
    'ColisseumRegular24' |
    'ColisseumRegular27' |
    'ColisseumRegular30' |
    'ColisseumRegular40' |
    'ColisseumRegular44' |
    'ColisseumRegular48' |
    'ColisseumRegular58' |
    'ColisseumBold44' |
    'ColisseumBold48' |
    'RocGroteskRegular14' |
    'RocGroteskRegular16' |
    'RocGroteskRegular18' |
    'RocGroteskRegular20' |
    'RocGroteskRegular25' |
    'RocGroteskRegular30' |
    'RocGroteskMedium16' |
    'RocGroteskMedium18' |
    'RocGroteskMedium20' |
    'RocGroteskMedium25' |
    'RocGroteskBold16' |
    'RocGroteskBold18' |
    'RocGroteskBold20' |
    'RocGroteskBold25' |
    'RocGroteskBold30' |
    'RocGroteskBold33';


interface TypographyProps extends Omit<MuiTypographyProps, 'variant'> {
    variant: ExtendedTypographyVariant
    component?: string
}

/**
 * solução de recurso para lidar com o problema de adicionar
 * tamanhos personalizados ao tema do material-ui, ver E#264
 */
export default function Typography(props: TypographyProps) {
    const { variant, children, className, ...rest } = props

    const isCustom = isCustomVariant(variant)
    const appliedVariant = isCustom ? 'body1' : variant

    const combinedClassName = `${styles[capitalize(variant)]} ${className || ''}`;

    return (
        <MuiTypography
            variant={appliedVariant as MuiTypographyProps['variant']}
            className={combinedClassName}
            {...rest}
        >
            {children}
        </MuiTypography >
    )
}
