import { Grid } from '@material-ui/core';
import React from 'react';
import { useGtXs } from '../../hooks/useGtXs';
import OutlineButton from '../OutlineButton/OutlineButton';
import Paragraph from '../Paragraph/Paragraph';
import styles from './BalleteatroView.module.scss';

export default function BalleteatroView() {

    const url = "https://www.balleteatro.pt"
    const gtXs = useGtXs()

    const titleVariant = gtXs ? "ColisseumBold48" : "ColisseumBold44"
    const subtitleVariant = gtXs ? "ColisseumRegular44" : "ColisseumRegular40"

    const textVariant = gtXs ? "RocGroteskRegular25" : "RocGroteskRegular20"

    return (
        <div>
            <div className={styles.SectionBanner} />
            <Grid container justify="center" className={styles.Wrapper}>
                <main>
                    <Grid container direction="column" justify="center">
                        <div className={styles.Title}>
                            <Paragraph variant={titleVariant}>
                                BALLETEATRO
                            </Paragraph>
                            <Paragraph variant={subtitleVariant}>
                                ESTRUTURA ARTÍSTICA RESIDENTE
                            </Paragraph>
                        </div>
                        <div>
                            <Paragraph variant={textVariant}>
                                O Balleteatro é, desde setembro de 2015, a estrutura artística residente do Coliseu Porto. Há já vários anos que o edifício do Coliseu mantinha um conjunto de salas e espaços vazios, que fazia todo o sentido que fossem abertos e disponibilizados aos cidadãos. Receber uma escola artística nas instalações do Coliseu revelou-se a forma perfeita de habitar este espaço e de o devolver em definitivo à cidade. Agora o Coliseu é diretamente revitalizado pela presença de alunos e professores, que enchem as salas de teoria do Teatro e da Dança, práticas artísticas e ensaios.
                                Aderir à Associação Amigos do Coliseu do Porto tem vários benefícios.
                            </Paragraph>
                        </div>
                        <Grid container justify="center" className={styles.Action}>
                            <OutlineButton
                                name="Balleteatro"
                                route={url}
                                fullWidth={false}
                                uppercased={true}
                            />
                        </Grid>
                    </Grid>
                </main>
            </Grid >
        </div >
    )
}
