import { Grid } from '@material-ui/core';
import React from 'react';
import { useGtXs } from '../../hooks/useGtXs';
import OutlineButton from '../OutlineButton/OutlineButton';
import Paragraph from '../Paragraph/Paragraph';
import styles from './FriendsView.module.scss';

export default function FriendsView() {

    const url = "https://amigos.coliseu.pt"
    const gtXs = useGtXs()

    const variant = gtXs ? "RocGroteskBold25" : "RocGroteskBold20"
    const textVariant = gtXs ? "RocGroteskRegular25" : "RocGroteskRegular20"

    return (
        <div>
            <div className={styles.SectionBanner} />
            <Grid container justify="center" className={styles.Wrapper}>
                <main>
                    <Grid container direction="column" justify="center">
                        <div>
                            <Paragraph variant={variant} style={{ textAlign: "center" }}>
                                Há sempre lugar para mais um Amigo!
                            </Paragraph>
                        </div>
                        <div>
                            <Paragraph variant={textVariant} style={{ textAlign: "center" }}>
                                Aderir à Associação Amigos do Coliseu do Porto tem vários benefícios.
                            </Paragraph>

                            <Paragraph variant={textVariant} style={{ textAlign: "center" }}>
                                Clique no botão abaixo para saber mais sobre os Amigos do Coliseu.
                            </Paragraph>
                        </div>
                        <Grid container justify="center" className={styles.Action}>
                            <OutlineButton
                                name="Amigos Coliseu"
                                route={url}
                                fullWidth={false}
                            />
                        </Grid>
                    </Grid>
                </main>
            </Grid>
        </div>
    )
}
