import { Grid } from '@material-ui/core';
import React from 'react';
import { useGtXs } from '../../hooks/useGtXs';
import OutlineButton from '../OutlineButton/OutlineButton';
import Paragraph from '../Paragraph/Paragraph';
import styles from './MantrasView.module.scss';

export default function MantrasView() {

    const url = "https://mantras.coliseu.pt"

    const gtXs = useGtXs()

    const variant = gtXs ? "RocGroteskBold25" : "RocGroteskBold20"
    const textVariant = gtXs ? "RocGroteskRegular25" : "RocGroteskRegular20"

    return (
        <div>
            <div className={styles.SectionBanner} />
            <Grid container justify="center" className={styles.Wrapper}>
                <main>
                    <Grid container direction="column" justify="center">
                        <div>
                            <Paragraph variant={variant} style={{ textAlign: "center" }}>
                                Bem-vindo/a aos Mantras do Coliseu!
                            </Paragraph>

                            <Paragraph variant={textVariant} style={{ textAlign: "center" }}>
                                Todos os meses, desafiamos os públicos para um momento diferente do que se espera de uma grande Sala de Espetáculos: propostas diferenciadas, que trazem um convite ao pensamento, habitualmente em formato de conversa / debate - ainda que não exclusivamente -, e sempre com entrada livre. Consulte a nossa Agenda!
                            </Paragraph>

                            <Paragraph variant={textVariant} style={{ textAlign: "center" }}>
                                Os Mantras do Coliseu são também poéticos e musicais. Clique no botão abaixo para ouvir os Mantras originais que Pedro Abrunhosa, Manel Cruz, Pedro Burmester & Ricardo Burmester, Surma, Noiserv, Carlos Azevedo e Alexandre Soares compuseram para o Coliseu.
                            </Paragraph>
                        </div>
                        <Grid container justify="center" className={styles.Action}>
                            <OutlineButton
                                name="Mantras Coliseu"
                                route={url}
                                fullWidth={false}
                            />
                        </Grid>
                    </Grid>
                </main>
            </Grid>
        </div>
    )
}
