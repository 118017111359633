import elo_gallery_1 from '../assets/images/educational/elo/gallery/galeria_elo_01.jpg';
import elo_gallery_2 from '../assets/images/educational/elo/gallery/galeria_elo_02.jpg';
import elo_gallery_3 from '../assets/images/educational/elo/gallery/galeria_elo_03.jpg';
import elo_gallery_4 from '../assets/images/educational/elo/gallery/galeria_elo_04.jpg';
import elo_gallery_5 from '../assets/images/educational/elo/gallery/galeria_elo_05.jpg';
import elo_gallery_6 from '../assets/images/educational/elo/gallery/galeria_elo_06.jpg';
import elo_gallery_7 from '../assets/images/educational/elo/gallery/galeria_elo_07.jpg';
import elo_gallery_8 from '../assets/images/educational/elo/gallery/galeria_elo_08.jpg';
import elo_gallery_9 from '../assets/images/educational/elo/gallery/galeria_elo_09.jpg';
import elo_gallery_10 from '../assets/images/educational/elo/gallery/galeria_elo_10.jpg';
import elo_gallery_11 from '../assets/images/educational/elo/gallery/galeria_elo_11.jpg';
import elo_gallery_12 from '../assets/images/educational/elo/gallery/galeria_elo_12.jpg';
import elo_gallery_13 from '../assets/images/educational/elo/gallery/galeria_elo_13.jpg';
import elo_gallery_14 from '../assets/images/educational/elo/gallery/galeria_elo_14.jpg';
import elo_gallery_15 from '../assets/images/educational/elo/gallery/galeria_elo_15.jpg';
import elo_gallery_16 from '../assets/images/educational/elo/gallery/galeria_elo_16.jpg';

const eloGalleryPhotos = [
    elo_gallery_1,
    elo_gallery_2,
    elo_gallery_3,
    elo_gallery_4,
    elo_gallery_5,
    elo_gallery_6,
    elo_gallery_7,
    elo_gallery_8,
    elo_gallery_9,
    elo_gallery_10,
    elo_gallery_11,
    elo_gallery_12,
    elo_gallery_13,
    elo_gallery_14,
    elo_gallery_15,
    elo_gallery_16,
]

export default eloGalleryPhotos
